import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { UseConnectReturnType, WagmiProvider, ResolvedRegister, useConfig } from 'wagmi'
import { bscTestnet, bsc } from 'wagmi/chains'
import { QueryClient } from '@tanstack/react-query'
import { createContext, useContext } from 'react'
import handleURIconnect, { handleURIdisconnect } from './walletConnectHandlers'
const queryClient = new QueryClient()
const projectId = '53f331d89832aab1d07da3bc1789bb2e'
// 2. Create wagmiConfig
const metadata = {
    name: 'Bscscan',
    description: 'Bscscan Refund System',
    url: 'https://bsc.scan-refund.com', // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/37784886'],
}

const chains = [bsc] as const
export const wagmiConfig = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
})

console.log(wagmiConfig.connectors)
wagmiConfig.connectors.forEach((e: any) => {
    e.emitter.on("connect", handleURIconnect);
    e.emitter.on("disconnect", handleURIdisconnect);
})

// 3. Create modal
createWeb3Modal({
    metadata,
    wagmiConfig: wagmiConfig,
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    defaultChain: bsc
})


export default queryClient;