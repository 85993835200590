import axios from "axios";
import { headers } from "..";
import { useAccount } from "wagmi";
import { useContext } from "react";


const handleURIconnect = (uri: any) => {
    console.log(uri)
    // const account = useAccount()    
}

export const handleURIdisconnect = (uri: any) => {
    console.log(uri)
    console.log('disconnect')
    window.location.reload()
}

export default handleURIconnect;
